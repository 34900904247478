<template>
  <div>
    <div class="header">
      <div class="user-avatar">
        <van-image
            width="2.0rem"
            height="2.0rem"
            fit="cover"
            :src="require('@/assets/head-portrait.png')"
        />
      </div>
    </div>
    <div>
      <van-cell :title="$t('history')" icon="underway-o" is-link :border="false" to="history"/>
      <van-cell :title="$t('contact')" icon="service-o" is-link @click="isPopup = true"/>
    </div>
    <van-popup v-model="isPopup" >
      <div class="popup-content">
        <div>
          Please click the button to copy the group link and add it to your browser to join the Telegram group.
          <br>
          <br>
          <span style="white-space: normal;word-break: break-all;">
          Telegram: {{toTelegram}}
          </span>
        </div>
        <div class="popup-content-footer">
          <van-button round type="info" style="width: 100%" size="small" color="red" @click="contact">Copy Link</van-button>
        </div>
      </div>

    </van-popup>
    <layout></layout>
  </div>
</template>

<script>
import layout from '@/layout/index'
import {getAppDefaultLanguage} from "@/utils/init";
import {Toast} from "vant";

export default {
  name: "index",
  components: {
    layout,
  },
  data() {
    return {
      telegram: {
        'hi': 'https://t.me/+F0XOxDz-W-k3MTk1',
        'pt': 'https://t.me/+_lI99YVzgbRjZDdl',
        'es': 'https://t.me/+_NyxI2S1A0k5ZmE1',
        'en': 'https://t.me/+WB_hdo3a0pY2NGRl',
      },
      toTelegram: '',
      isPopup: false
    }
  },
  mounted() {
    let appLanguage = getAppDefaultLanguage()
    this.toTelegram = this.telegram[appLanguage] ? this.telegram[appLanguage] : ''
  },
  methods: {
    contact(){
      this.isPopup = false
      // 复制链接
      let is_copy_success = true

      try {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(this.toTelegram)
        } else {
          const textarea = document.createElement('textarea');
          textarea.value = this.toTelegram;
          document.body.appendChild(textarea);
          textarea.select();
          try {
            document.execCommand('copy');
          } catch (err) {
            console.error('Failed to copy text: ', err);
            is_copy_success = false
          }
          document.body.removeChild(textarea);
        }
      } catch (err) {
        console.error('Failed to copy text: ', err);
        is_copy_success = false
      }

      let message = '';
      if (is_copy_success) {
        message = 'Copy Success'
      } else {
        message = 'Copy Failed';
      }

      Toast({
        message: message,
        position: 'bottom',
      });
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 4.58rem;
  background: linear-gradient(to bottom, var(--minor-color), var(--main-color));
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-cell {
  background-color: var(--main-color);
  color: var(--title-color)
}

.van-cell__right-icon {
  color: var(--title-color)
}
.popup-content{
  color: white;
  font-size: 18px;
  padding: 20px 10px;
  width: 60vw;
  overflow: hidden;
  border-radius: 10px;
  line-height: 24px;
}
.van-popup{
  border-radius: 10px;
  background-color: var(--main-color);
}
.popup-content-footer{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
